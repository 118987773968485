import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Invokacija = () => (
  <Layout>
    <SEO title="Ja u svijetu" />
    <div class="styledtext layout__container--med">
      <h3>Invokacija</h3>
      <p>
        <strong>Eliksir Života</strong>
      </p>
      <p>
        Ja sam energija koja postoji oduvijek i zauvijek. Moja je duša došla igrati se s iskustvom obilja, rasti i mijenjati sebe i svijet. Zaslužujem život pun čudesnosti i nevjerojatnih, inspirativnih priča jer ja sam Čudesno Vibracijsko Biće, stvoreno od Jednoga da bi ostalo Jedno, uvijek se iznova dijeleći od Izvora i uvijek mu se iznova vraćajući.
      </p>
      <p>
        Dobro mi došao novi danu u kojem ću iskusiti svu veličanstvenost slobode, radosti, lakoće, čuda, izvrsnosti, neograničenosti, vječnosti u ovoj prekrasno orkestriranoj Igri zvanoj Život.
      </p>

      <p>
        Baš me zanima koji će mi se sve danas putevi otvoriti, koliko ću vrata i tajanstvenih prolaze danas prepoznati na putu prema ostvarenju svoje svrhe s kojom dođoh na ovaj svijet. Baš me zanima koji će se sve kokreatori na tim putevima pojaviti; koliko ću duša s kojima davno sklopih savez ekspanzije danas prepoznati.
      </p>

      <p>
        Svemire, čekam da primijetim…
      </p>
    </div>
  </Layout>
)

export default Invokacija
